<template>
  <q-dialog v-model="isOpen">
    <q-card style="min-width: 64vw;">
      <q-card-section class="row items-center">
        <div class="text-h6 text-center q-mr-sm">
          {{ $t('Location logs') }}
        </div>


        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>


      <q-card-section class="text-center">
        <q-table
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="loading"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="id"
                  :props="props"
                  class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>
              </q-td>

              <q-td
                  key="place"
                  :props="props"
                  style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
              >
                <div class="text-subtitle1">
                <span v-if="props.row._embedded?.place">
                  <place-object
                      :data="props.row._embedded.place"
                      :type="props.row._embedded.place.type"
                      class="q-mb-sm"
                  />
                </span>

                  <span v-else>--</span>
                </div>
              </q-td>

              <q-td
                  key="type"
                  :props="props"
              >
                <q-chip v-if="props.row.type === 'move'" class="q-my-none text-center q-px-lg" size="md" square color="red-4">
                  {{ props.row.type }}
                </q-chip>
                <q-chip v-else class="q-my-none text-center q-px-lg" size="md" square color="green-4">
                  {{ props.row.type }}
                </q-chip>
              </q-td>

              <q-td
                  key="count"
                  :props="props"
              >
                {{ props.row.count }}
              </q-td>

              <q-td
                  key="created"
                  :props="props"
              >
                {{ $moment(props.row.created).format($appOptions.formats.fullDate) }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

export default {
  name: 'LocationLogsModal',
  data () {
    return {
      isOpen: false,
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Place'),
          name: 'place',
          align: 'center'
        },
        {
          label: this.$t('Type'),
          name: 'type',
          align: 'left'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        }
      ],
      loading: false,
      items: [],
      isOpenFilter: false,
      activatedFields: [
        'id',
        'created.from',
        'created.to'
      ],
      filterParams: [],
      offer: null
    }
  },
  computed: {
    colorOfType (type) {
      if (type === 'move') {
        return 'red-4'
      }
      return 'green-4'
    },
  },
  methods: {
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      if (!query.per_page) {
        query.per_page = 25
      }

      if (!query.filter) {
        query.filter = []
      }

      if (this.offer) {
        query.filter.push({ type: 'eq', field: 'productOffer', value: this.offer.id })
      }

      query['order-by'] = [
        { type: 'field', field: 'created', direction: 'desc' }
      ]

      this.filterParams = query.filter
      this.loading = true

      return this.$service.locationLog.getAll(query)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.loading = false
          })
    },
    close () {
      this.isOpen = false
    },
    open (offer) {
      this.offer = offer
      this.isOpen = true
      this.onRequest({ pagination: { per_page: 25, page: 1 } })
    }
  }
}
</script>
